/* eslint-disable react/jsx-no-target-blank */
import React from 'react'

import styles from './SaveMeduza.module.css'

interface SaveMeduzaProps {
  lang: 'ru' | 'en'
}

export const SaveMeduza: React.FC<SaveMeduzaProps> = ({ lang }) => {
  if (lang === 'ru') {
    return (
      <div className={styles.button}>
        <a
          className={styles.ru}
          href="https://support.meduza.io/?utm_source=webbutton&utm_medium=ru"
          target="_blank"
        >
          <span>10 лет с «Медузой». И хочу еще!</span>
        </a>
      </div>
    )
  }
  return (
    <div className={styles.root}>
      <a
        href="https://support.meduza.io/en?utm_source=webbutton&utm_medium=en"
        target="_blank"
      >
        <span>Meduza is 10. Support us!</span>
      </a>
      <div className={styles.overlay} />
    </div>
  )
}
