export const POPOVER_STORAGE_KEY = 'popover'

export const POPOVER_STYLES = [
  {
    primaryColor: '#DFCFFE',
    variant: 'variantFirst'
  },
  {
    primaryColor: '#E7FFC8',
    variant: 'variantSecond'
  },
  {
    primaryColor: '#FED1EB',
    variant: 'variantThird'
  },
  {
    primaryColor: '#FFFCBE',
    variant: 'variantFourth'
  }
]

export const POPOVER_BANNER_CONTENT = {
  ru: {
    title:
      '«Медузе» 10 лет! <span>10 дней подряд мы будем рассказывать вам маленькие истории из нашей жизни</span>',
    buttonText: 'Поддержать'
  },
  en: {
    title:
      'Meduza is 10 years old! <span>For the next 10 days, we’ll be sharing some stories from our journey so far.</span>',
    buttonText: 'Support Meduza'
  }
}
