import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPopoverBanner } from '@client/selectors/appSelectors'
import { getLang } from '@client/selectors/currentUserSelectors'
import { fetchPopoverBanner } from '@client/store/App/appActions'
import { getRandomInt } from '@client/utils/getRandomInt'

import { POPOVER_STYLES } from './constants'
import { usePopoverBannerStorage } from './helpers'
import { PopupBanner } from './PopupBanner'

export const PopupBannerContainer = () => {
  const [shouldRender, setShouldRender] = useState(false)
  const { storage, isDismissed } = usePopoverBannerStorage()
  const bannedData = useSelector(getPopoverBanner)
  const lang = useSelector(getLang)

  const dispatch = useDispatch()

  useEffect(() => {
    if (bannedData || isDismissed) {
      return
    }

    dispatch(fetchPopoverBanner.request())
  }, [dispatch, bannedData, isDismissed])

  useEffect(() => {
    if (!storage || isDismissed) {
      return
    }

    setShouldRender(!isDismissed)
  }, [dispatch, storage, isDismissed])

  if (!bannedData) {
    return null
  }

  const content = bannedData?.find((item) => item.locale === lang)

  if (!content) {
    return null
  }

  const randomStyle = getRandomInt(0, POPOVER_STYLES.length - 1)

  const banner = {
    ...content,
    ...POPOVER_STYLES[randomStyle]
  }

  return (
    <PopupBanner
      content={banner}
      shouldRender={shouldRender}
      setShouldRender={setShouldRender}
    />
  )
}
