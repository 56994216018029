import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { ExchangeRates } from '@common/ExchangeRates'
import { InformerContainer } from '@common/Informer/InformerContainer'
import { ViewSwitcherContainer } from '@common/ViewSwitcherContainer/ViewSwitcherContainer'
import { getScreens } from '@selectors/screensSelectors'
import equal from 'fast-deep-equal/es6'

/*
 * Панель под меню на десктопе
 */
import styles from './InfoPanel.module.css'

export const InfoPanelComponent = () => {
  const { informer } = useSelector(getScreens, equal)

  return (
    <div className={styles.root}>
      <div className={styles.exchangeRates}>
        <ExchangeRates />
      </div>

      {informer && (
        <div className={styles.informer}>
          <InformerContainer informer={informer} />
        </div>
      )}

      <div className={styles.switcher}>
        <ViewSwitcherContainer styleContext="isInPanel" />
      </div>
    </div>
  )
}

export const InfoPanel = memo(InfoPanelComponent)
