import { useEffect, useState } from 'react'
import { localStorageService } from '@client/utils/localStorageService'
import dayjs from 'dayjs'

import { POPOVER_STORAGE_KEY } from './constants'
import { PopoverBannerStorage } from './PopupBanner.types'

const initialStorage: PopoverBannerStorage = {
  dismissed: null
}

export const setPopoverBannerInitialStorage = () =>
  localStorageService.set(POPOVER_STORAGE_KEY, initialStorage)

export const setPopoverBannerDismissed = () => {
  const today = dayjs()
  const dismissed = today.add(1, 'day').toISOString()

  localStorageService.set(POPOVER_STORAGE_KEY, { dismissed })
}

export const getPopoverBannerStorage = (): PopoverBannerStorage =>
  localStorageService.get(POPOVER_STORAGE_KEY)

const getPopoverBannerDismissed = (): string | null =>
  getPopoverBannerStorage()?.dismissed

export const isPopoverBannerDismissed = (): boolean => {
  const dismissed = getPopoverBannerDismissed()

  if (!dismissed) {
    return false
  }

  return dayjs().isBefore(dayjs(dismissed))
}

export const popoverBannerStorage = () => {
  const storage = getPopoverBannerStorage()

  if (!storage) {
    setPopoverBannerInitialStorage()
  }

  return storage || initialStorage
}

export const usePopoverBannerStorage = () => {
  const [storage, setStorage] = useState<PopoverBannerStorage>(null)

  useEffect(() => {
    if (storage) {
      return
    }

    const nextStorage = popoverBannerStorage()
    setStorage(nextStorage)
  }, [storage])

  return {
    storage,
    isDismissed: isPopoverBannerDismissed()
  }
}
