import React from 'react'
import { useSelector } from 'react-redux'
import { getLang } from '@client/selectors/currentUserSelectors'

import { POPOVER_BANNER_CONTENT } from './constants'
import { PopoverBanner } from './PopupBanner.types'

import styles from './PopoverBannerContent.module.css'

export const PopoverIcon = () => (
  <svg width="11" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.883 1.048 4.742 2.24a2.857 2.857 0 0 1-2.501 2.5l-1.193.142c-.73.087-.73 1.147 0 1.234l1.193.141a2.857 2.857 0 0 1 2.5 2.501l.142 1.193c.087.73 1.147.73 1.234 0l.141-1.193a2.857 2.857 0 0 1 2.501-2.5l1.193-.142c.73-.087.73-1.147 0-1.234L8.76 4.742a2.857 2.857 0 0 1-2.5-2.501l-.142-1.193c-.087-.73-1.147-.73-1.234 0Z"
      fill="var(--primaryColor)"
      stroke="#000"
    />
  </svg>
)

export const PopoverBannerContent = ({
  content
}: {
  content: PopoverBanner
}) => {
  const lang = useSelector(getLang)

  const { text, buttonUrl } = content
  const { title, buttonText } = POPOVER_BANNER_CONTENT[lang]

  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <h3
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <a
          className={styles.button}
          href={buttonUrl}
          target="_blank"
          type="button"
          rel="noopener noreferrer"
        >
          {buttonText}

          <span className={styles.icon}>
            <PopoverIcon />
          </span>
        </a>
      </div>
    </div>
  )
}
