import React from 'react'
export const PopoverDismissIcon = () => (
  <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.9236 12.9231L29.0774 29.077"
      stroke="#000"
      strokeWidth="2.42315"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.0774 12.9231L12.9236 29.077"
      stroke="#000"
      strokeWidth="2.42315"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
