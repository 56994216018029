import React from 'react'
import { useSelector } from 'react-redux'
import { PodcastMaterialContent } from '@client/types'
import { PodcastCover } from '@common/PodcastCover'
import { RenderBlocks } from '@common/RenderBlocks'
import { getLang } from '@selectors/currentUserSelectors'

import { Button, Dropdown, Menu } from '@meduza/ui-kit-2'

import styles from './PodcastMaterial.module.css'

export interface PodcastMaterialProps {
  material: PodcastMaterialContent
}

export const PodcastMaterial: React.FC<PodcastMaterialProps> = ({
  material: {
    url,
    layout,
    image,
    podcast,
    content: { blocks, head },
    container: { display, mods = [] }
  }
}) => {
  const lang = useSelector(getLang)

  const modifiers: string[] = [layout]

  if (display) modifiers.push(display)
  if (mods) modifiers.splice(mods.length, 0, ...mods)

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={styles.container}>
          <div className={styles.image}>
            {image && (
              <PodcastCover
                image={image.normal_retina_url}
                styleContext="isInPodcastMaterial"
              />
            )}
          </div>
          {head.map((block) => (
            <RenderBlocks
              url={url}
              key={block.id}
              block={block}
              styleContext={['podcast', 'podcastMaterial']}
            />
          ))}
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.panel}>
          {podcast.media_platform_blocks && (
            <div className={styles.subscribe}>
              <Dropdown>
                <Button size="auto" theme="ghost">
                  {lang === 'ru'
                    ? 'Слушать на других платформах'
                    : 'Choose your listening platform'}

                  <div className={styles.arrow} />
                </Button>
                <Menu>
                  <ul>
                    {podcast.media_platform_blocks.map((item) => (
                      <li key={`menu-${item.title}`}>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Menu>
              </Dropdown>
            </div>
          )}
        </div>

        {blocks.map((block) => (
          <RenderBlocks
            url={url}
            key={block.id}
            block={block}
            styleContext={modifiers}
          />
        ))}
      </div>
    </div>
  )
}
