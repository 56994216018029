import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from '@common/Modal'
import { AudioPlayerControls } from '@components/Layout/AudioPlayerControls'
import { Footer } from '@components/Layout/Footer'
import { Header } from '@components/Layout/Header'
import { InfoPanel } from '@components/Layout/InfoPanel'
import { SaveMeduza } from '@components/Layout/SaveMeduza/'
import { getApp } from '@selectors/appSelectors'
import { getLang } from '@selectors/currentUserSelectors'
import { getModal } from '@selectors/modalSelectors'
import equal from 'fast-deep-equal'

import { GDPRPanelContainer } from './GDPRPanel/GDPRPanelContainer'
import { BannerContainer } from './BannerContainer'
import { FootnoteContainer } from './FootnoteContainer'
import { TooltipContainer } from './TooltipContainer'

import styles from './Layout.module.css'

interface LayoutProps {
  pathname: string
  routeName: string
  materialName?: string
  type: string
  children: React.ReactNode
}

export const LayoutComponent: React.FC<LayoutProps> = ({
  pathname,
  routeName,
  materialName,
  type,
  children
}) => {
  const { isEmbedded, isPreview } = useSelector(getApp, equal)
  const lang = useSelector(getLang)
  const { type: modalType } = useSelector(getModal, equal)

  const showInfoPanel = !isPreview && type === 'screen' && lang === 'ru'
  const isMaterial = routeName === 'material'
  const isBrand = materialName === 'brand'

  return (
    <div
      className={styles.root}
      data-pathname={pathname}
      data-route={routeName}
      data-material={materialName}
    >
      <a className={styles.skipLink} href="#maincontent">
        {lang === 'ru' ? 'Перейти к материалам' : 'Skip to main content'}
      </a>

      {!isBrand && (
        <>
          <div key="banner" className={styles.banner}>
            <BannerContainer lang={lang} isMaterial={isMaterial} />
          </div>

          <div className={styles.header} data-inapp-hide="true">
            <Header />

            <SaveMeduza lang={lang} />

            {showInfoPanel && <InfoPanel />}
          </div>
        </>
      )}

      <main key="client" className={styles.content} id="maincontent">
        {children}
      </main>

      {modalType && <Modal />}

      {!isBrand && (
        <>
          <footer className={styles.footer}>
            <Footer />
          </footer>

          <div className={styles.controls}>
            <AudioPlayerControls url={pathname.substring(1)} />
          </div>
          {!isEmbedded && !isPreview && <GDPRPanelContainer lang={lang} />}
        </>
      )}

      {type === 'material' && (
        <>
          <FootnoteContainer />
          <TooltipContainer />
        </>
      )}
    </div>
  )
}

export const Layout = memo(LayoutComponent)

Layout.whyDidYouRender = true
