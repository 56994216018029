import { useCallback, useEffect, useState } from 'react'

export const useTriggerOnScroll = (percent: number): boolean => {
  const [triggered, setTriggered] = useState(false)

  const handleScroll = useCallback(() => {
    if (window.scrollY >= percent && !triggered) {
      setTriggered(true)
    }
  }, [triggered, percent])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return triggered
}
